$(function () {
    $('.input_error').hide();

    let nameInput = $('#name_input');
    let emailInput = $('#email_input');
    let commentInput = $('#comment');

    let usernameError = true;
    let emailError = true;
    let commentError = true;
    let inputElement;

    function validateInput(input_element, error_notification) {
        let inputValue = input_element.val();
        if (inputValue.length == '') {
            error_notification.show();
            input_element.css('border-color', 'red');
            if (inputElement == null) inputElement = input_element
            return false;
        } else {
            input_element.css('border-color', '#A9A2B0');
            error_notification.hide();
            return true;
        }
    }

    function validateEmailRegx() {
        let regex = /^([_\-\.0-9a-zA-Z]+)@([_\-\.0-9a-zA-Z]+)\.([a-zA-Z]){2,7}$/;
        let s = emailInput.val();
        if (regex.test(s)) {
            emailInput.css('border-color', '#A9A2B0');
            $('#emailcheck-regx').hide();
            emailError = true;
        } else {
            emailInput.css('border-color', 'red');
            $('#emailcheck-regx').show();
            emailError = false;
        }
    }

    function validateContactForm() {

        nameInput.on("input", function () {
            validateInput($(this), $('#usercheck'))
        });
        commentInput.on("input", function () {
            validateInput($(this), $('#commentcheck'))
        });
        emailInput.on("input", function () {
            if (validateInput($(this), $('#emailcheck'))) validateEmailRegx();
        });

        usernameError = validateInput(nameInput, $('#usercheck'));
        emailError = validateInput(emailInput, $('#emailcheck'));
        commentError = validateInput(commentInput, $('#commentcheck'));
        if (emailError) emailError = validateEmailRegx;
        if (usernameError && emailError && commentError) {
            return true
        } else {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(inputElement).offset().top - 130,
                duration: 1000,
            });
            event.preventDefault();
        }

    }


    $('#btn_contact').hover(function () {
        $(this).addClass('hovered')

    }, function () {
        $(this).removeClass('hovered')
    })


    $('#btn_contact').click(function () {
        if (!$("#checkbox_contact")[0].checked) {
            var arrow = "<img src='/arrow.svg' id='contact_arrow'/>"
            if ($('#contact_arrow').length == 0) $("#div_for_contact_button").append(arrow)

            setTimeout(animateUp, 0)
            setTimeout(animateUp, 800)
        }
    })

    function animateDown() {
        checkbox_cont = $('#div_for_contact_checkbox')
        checkbox_cont.removeClass('big_text')
    }

    function animateUp() {
        checkbox_cont = $('#div_for_contact_checkbox')
        checkbox_cont.addClass('big_text')
        setTimeout(animateDown, 500)
    }

    $('.contact_form').submit(function () {
        validateContactForm();
        if (!$("#checkbox_contact")[0].checked) {
            event.preventDefault();
            var arrow = "<img src='/arrow.svg' id='contact_arrow'/>"
            if ($('#contact_arrow').length == 0) $("#div_for_contact_button").append(arrow)

            setTimeout(animateUp, 0)
            setTimeout(animateUp, 800)
        }

    })
})