export const init = () => {

    $('.accordion-button').click(function () {
        let button = this
        let accordionButton = $(button);
        let accordionContent = $(accordionButton.data('bsTarget')) || $(accordionButton.data('target'));
        if (accordionButton.attr('aria-expanded') == 'true' || accordionButton.hasClass('active')) {
            let openButtons = $('.collapsing');
            let openBlock = $(openButtons[0]).attr('id') == accordionContent.attr('id') ? 0 : $(openButtons[0]).outerHeight();
            $('html, body').animate({
                scrollTop: ($(button).offset().top - $('nav').outerHeight() - 40 - openBlock)
            }, 200);
            // extendCarousel(accordionButton)
            // setTimeout(() => {
            //     extendCarousel(accordionButton)
            // })
        }
    })
    $('.appartement-articles-container .collapse').each(function () {
        document.getElementById(this.id).addEventListener('shown.bs.collapse', function () {
            $('.appartement-articles-container').slick("slickGoTo", $('.appartement-articles-container').slick("slickCurrentSlide"))
        })
    })

    function extendCarousel(button) {
        let buttonTargetID = button.data('bsTarget')
        let buttonTarget = $(buttonTargetID)
        let accordionBlock = $(buttonTarget.data('bsParent'));
        let collapsingBlock = $(`.accordion-collapse.collapsing:not(${buttonTargetID})`)
        even = accordionBlock
        let hh = $('.appartement-articles-container').children().outerHeight() - collapsingBlock.outerHeight() + buttonTarget.outerHeight()
        // $('.appartement-articles-container').children().css('height', accordionBlock.outerHeight())
        $('.appartement-articles-container').children().css('height', hh)
    }

}