 $(function() {
   $(document).on("turbolinks:load", function () {
     function start() {
       $('#high_speed').find('.progress-bar').css({width: '100%', transition: 'all 5s ease'});
       $('#medium_speed').find('.progress-bar').css({width: '40%', transition: 'all 6.9s ease'});
       $('#low_speed').find('.progress-bar').css({width: '20%', transition: 'all 7.6s ease'});
     }

     function startOnScroll() {
       $(window).scroll(function () {
         if ($('#speed_picture').length == 0) return;
         var hT = $('#speed_picture').offset().top,
             hH = $('#speed_picture').outerHeight(),
             wH = $(window).height(),
             wS = $(this).scrollTop();

         if (wS > (hT + hH - wH) && !(wS > (hT + hH))) {
           start();
         }
       });
     }

     startOnScroll();
   })
});
