export const init = () => {
    $('.info-switcher').off('click').on('click', function () {
        let button = $(this);
        $('.info-item.mobile').slideUp();

        if (button.hasClass('active')) return $('.info-switcher').removeClass('active');;

        let wasNoActiveEl = $('.info-switcher.active').length === 0;

        $('.info-switcher').removeClass('active');

        let targetId = button.data('target');
        let target = $(targetId);

        $(`.info-switcher[data-target="${targetId}"]`).addClass('active');

        if (button.hasClass('mobile')) {
            target.slideDown(400, function () {
                $('html, body').animate({
                    scrollTop: ($(button).offset().top - $('nav').outerHeight())
                }, 200);
            })
        } else if (wasNoActiveEl) {
            target.slideDown();
        } else {
            $('.info-item').slideUp().delay(600).after(target.slideDown());
        }
    })
}