export const init = () => {

    $('.vacancies-carousel').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchMove: false,
        nextArrow: $('#vacations-next'),
        prevArrow: $('#vacations-prev'),
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]

    });

    $('.testimonials-carousel').slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        nextArrow: $('#testimonials-next'),
        prevArrow: $('#testimonials-prev'),
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    })
    $('.communes-carousel').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchMove: false,
        nextArrow: $('#communes-next'),
        prevArrow: $('#communes-prev'),
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]

    });
    let element


    $('.stage-number, .stage-name').click(function () {
        let button = $(this);
        let target = $(button.data('description-target'));
        let descriptions = $('.stage-description');
        let parent = button.parent()
        $('.stage-element').removeClass('active');
        if (!parent.hasClass('mobile')) parent.addClass('active')
        descriptions.hide();
        target.show();
    })

    $('.stage-description-toggler').click(function () {
        let button = $(this);
        let target = $(button.data('description-target'));
        let descriptions = $('.stage-description');
        descriptions.slideUp();
        if (button.hasClass('open')) {
            button.text('To learn more');
            button.removeClass('open');
        } else {
            $('.stage-description-toggler').text('To learn more');
            $('.stage-description-toggler').removeClass('open');
            button.addClass('open');
            button.text('Roll up');
            target.slideDown();
        }
    })
}