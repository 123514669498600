// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require popper
//= require bootstrap-sprockets
//= require jquery-ui/widgets/datepicker

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery'
import 'jquery-ui-dist/jquery-ui'
import 'popper.js'
import "select2"

// import bootstrap from 'bootstrap'
import '@popperjs/core'
import 'slick-carousel/slick/slick.min'


//import 'jquery-validation';

require("trix")
require("@rails/actiontext")


Rails.start()
Turbolinks.start()
ActiveStorage.start()
global.$ = require('jquery')
const $ = global.$;
global.jQuery = $;
global.bootstrap = require('bootstrap')

require('./landing')
require('./formButton')
require('./formValidator')
require('./appartement')
// require('./fiberMap')
import * as nav from './nav'
import * as accordionScroll from './accordion';
import * as carousel from './carousel';
import * as homeCarousel from './homePageCarousel';
import * as infoTechnic from './infoTechnic';
import * as question from './question';
import 'justifiedGallery'
import Viewer from 'viewerjs';

window.load_zendesk_widget = () => {
    window.zEACLoaded = undefined;
    window.zE = undefined;
    $('[id="ze-snippet"],[id="launcher"],iframe[data-product="web_widget"][title="No content"][role="presentation"][tabindex="-1"][allow="microphone *"][aria-hidden="true"][src="about:blank"]').remove();  // Remove the previous script element

    let script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=a8921fd6-b8ae-4695-a089-52edce886214';
    script['data-turbolinks-track'] = 'reload';
    document.head.appendChild(script);

    script.onload = function () {
        zE(function () {
            zE.setLocale('fr');
        });

        window.zESettings = {
            webWidget: {
                contactForm: {subject: false},
                chat: {connectOnPageLoad: false}
            }
        };
    };
}

global.adjustMapMargins = function () {
    let navbarHeight = $('.fixed-top').outerHeight();
    const windowWidth = $(window).width();
    if (windowWidth <= 512) {
        $('#menu-map .connection-map').css('margin-top', navbarHeight + 44);
        $('.map-additional-menu').css('top', navbarHeight);
    } else if (windowWidth <= 992) {
            $('#menu-map .connection-map').css('margin-top', navbarHeight + 7);
            $('.map-additional-menu').css('top', navbarHeight + 10);
    } else {
        $('#menu-map .connection-map').css('margin-top', navbarHeight);
        $('.map-additional-menu').css('top', navbarHeight + 32);
    }
}

$(document).on("turbolinks:before-cache", function () {
    $('.vacancies-carousel').slick("destroy");
    $('.testimonials-carousel').slick("destroy");
    $('.article-carousel').slick("destroy");
    $('.communes-carousel').slick("destroy");

    $('.current-progress').stop(true, false);
    $('.sub-nav-group, .navbar').removeAttr( 'style' );
    $('.navbar').removeClass("opened-submenu");
    $('.sub-nav-group').removeClass( 'current_page_link active' );
    $('.link_hover').not('.current_page_link').hide();
    if ($('form').length > 0) $('form')[0].reset();
    if ($('#carouselLanding').length > 0) bootstrap.Carousel.getInstance(document.querySelector('#carouselLanding')).dispose()
})


$(document).on("turbolinks:load", function () {
    nav.init();
    accordionScroll.init();
    carousel.init();
    infoTechnic.init();
    question.init();

    $('.trix-content a').each(function () {
        let href = $(this).attr("href");
        if (!href.includes(location.hostname)) {
            $(this).attr("target", "_blank");
        }
    });
    $('.news_card, .vacancy-card, .page-item, .commune-card').click(function () {
        var path = $(this).find('a').attr('href');
        window.location = path;
    })
    homeCarousel.init();
    load_zendesk_widget();
    // $(window).on('CookiebotOnTagsExecuted', load_zendesk_widget);

    $('.alert-dismissible').on('closed.bs.alert', function() {
        adjustMapMargins();
    });

    $('.gallery').justifiedGallery({
        captions: false,
        rowHeight: 200,
        margins: 6,
        refreshSensitivity: 50,
        refreshTime: 1e3,
        border: 0
    });

    const viewer = new Viewer(document.getElementById('gallery'), {
        title: false,
        loop: false,
        movable: false,
        rotatable: false,
        zoomOnWheel: false,
        zoomable: false,
        transition: false,
        filter(image) {
            return image.classList.contains('image');
        },
        url(image) {
            return image.getAttribute('original_url');
        },
    });
})

$(document).on("turbolinks:before-visit", function () {
    history.scrollRestoration = 'manual';
})

$(document).on('turbolinks:click', function (event) {
    if (event.target.getAttribute('href').charAt(0) === '#' && window.location.pathname === "/") {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(event.target.getAttribute('href')).offset().top - 108
        }, 200);
        return event.preventDefault()
    }
})
