export const init = () => {

    if ($('#carouselLanding').length > 0) {
        const myCarousel = document.querySelector('#carouselLanding');
        const carousel = new bootstrap.Carousel(myCarousel, {
            ride: false,
            wrap: true
        })
        myCarousel.addEventListener('slide.bs.carousel', function (e) {

            let slideTo = $(e.relatedTarget).index() + 1;
            $('.current-progress').css('width', '0')
            $('bdi.current-page').text(slideTo.toString().padStart(2, '0'));
            $('.current-progress').stop(true, false);
            $('.current-progress').animate(
                {width: '100%'},
                {
                    duration: 5000,
                    specialEasing: {
                        width: 'linear'
                    },
                    done: function () {
                        carousel.next();
                    }
                })
        })

        $('.current-progress').animate({width: '100%'}, 5000, 'linear', function () {
            $('.current-progress').css('width', '0');
            carousel.next()
        });
    }

}