export const init = () => {
    function closeMobileFaqSelect() {
        $('.faq-switcher-select').slideUp()
        $('.faq-switchers.mobile').removeClass('active')
    }


    function switchFaq(itemIndex) {
        let faqContainers = $('.faq-container');
        let faqContainerVisible = $('.faq-container:visible');
        let targetContainer = $(faqContainers[itemIndex]);
        if (targetContainer.css('display') !== 'block') {
            faqContainerVisible.hide('slide', {direction: 'left'}, 450)
            targetContainer.delay(500).show('slide', {direction: 'left'}, 500)
            $('.faq-type-switcher-mobile.option').removeClass('active');
            $($('.faq-type-switcher-mobile.option')[itemIndex]).addClass('active');
        }
    }

    $('.faq-switcher-select, .faq-switchers ').blur(function () {
        closeMobileFaqSelect();
    })
    $('#faq-type-switcher-selector').on("click", function () {
        if ($('.faq-switcher-select').css('display') == 'none') {
            $('.faq-switcher-select').slideDown()
            $('.faq-switchers.mobile').addClass('active');
        } else {
            closeMobileFaqSelect()
        }
    })

    $('.faq-type-switcher, .faq-type-switcher-mobile.option').click(function () {
        let itemIndex = Number($(this).data("hover"))
        $('.faq-hover').animate({left: `${itemIndex * 50}%`}, 200);
        switchFaq(itemIndex)
        if ($(this).hasClass('faq-type-switcher-mobile')) {
            closeMobileFaqSelect();
            $('#faq-type-switcher-selector').children().slideUp()
            $('#faq-type-switcher-selector').html($(this.innerHTML).hide());
            $('#faq-type-switcher-selector').children().slideDown()
        }
    })




    $('.question-button-mobile[data-index="1"], #articles-1').show();
    $('.question-button-mobile[data-index="1"], .question-button[data-index="1"]').addClass('active');

}