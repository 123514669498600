document.addEventListener("turbolinks:load", function () {
    $('.article-carousel').not('.slick-initialized').slick();
    $('.carousel-accordion').click(function () {
        $('.article-carousel').slick("refresh");
    });

    function initQuestionBlock() {
        if ($(window).width() <= 576) {
            $('.appartement-articles-container').slick({
                infinite: false,
                prevArrow: '.option-prev',
                nextArrow: '.option-next',
                adaptiveHeight: true
            });

            $('.appartement-articles').show();
        } else $('.appartement-articles-container.slick-initialized').slick('unslick')
    }

    if ($('.appartement-articles-container').length > 0) {
        initQuestionBlock()
        window.onresize = initQuestionBlock;
    }

    $('.appartement-articles-container:not(.article-carousel)').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        if ($(event.target).hasClass('appartement-articles-container'))
            changeTitle($(`.appartement-articles[data-slick-index="${nextSlide}"]`))
    });

    $('.question-button').click(function () {
        const button = $(this);
        if (button.hasClass('active')) return
        changeOption(button);

        const index = button.data('index');
        $('.question-button-mobile').removeClass('active').hide();

        const mobileButton = $(`.question-button-mobile[data-index="${index}"]`);
        mobileButton.addClass('active').show();

        $('#current-option').text(mobileButton.find('.question-index').text());
    })

    function changeTitle(currentElement) {
        if ($(this).hasClass('slick-disabled')) return
        slideQuestion(currentElement);
    }

    function slideQuestion(currentElement) {
        const currentElementIndex = currentElement.data('titleId');
        const currentTitle = $(currentElementIndex);
        const nextElementIndexText = currentTitle.find('.question-index').text();
        $('.question-button-mobile').finish();
        $('.question-button-mobile.active').fadeOut(function () {
            $(this).removeClass('active')
            currentTitle.fadeIn(function () {
                $(this).addClass('active')
            })
        })

        $('#current-option').text(nextElementIndexText);

    }

    function changeOption(button) {
        const optionsAmount = button.parent().find('.question-button').length
        const target = $(button.data('target'));
        const buttonOrder = button.prevAll('.question-button').length
        $('.question-button').removeClass('active');
        $('.appartement-articles').hide();
        button.addClass('active');
        target.show();
        slideHover($(button.data('hover')), optionsAmount, buttonOrder);
    }

    function slideHover(hover, optionsAmount, order) {
        const blockHeight = hover.parent().outerHeight();
        const topMargin = (blockHeight / optionsAmount) * (order)
        if (hover.hasClass('active')) {
            hover.animate({top: `${topMargin}px`}, 250);
        } else {
            $('.active-hover').removeClass('active');
            hover.css('height', `${blockHeight / optionsAmount}px`);
            hover.css('top', `${topMargin}px`);
            hover.addClass('active');
        }
    }

})