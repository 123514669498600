export const init = () => {
    $('.navOption').attr('onclick', 'optionOpen(this)');
    $('.sub-nav-group').removeAttr('style');

    function navScroll() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            $('nav').addClass('scroll')
        } else $('nav').removeClass('scroll');
    }

    navScroll();

    window.onscroll = function () {
        navScroll()
    };

    const navbar = $('nav');

    window.optionOpen = function (target) {
        let option = $(target)
        let attachment = $(option.data('attachment'))
        if (!attachment.hasClass('active')) {
            $('.link_hover').filter(':not(.current_page_link)').hide();
            option.find('.link_hover').css('display', 'flex');
            const active_attachment = $('.sub-nav-group.active');
            if (attachment[0] == null)
                closeSubCategory(active_attachment);
            else if (active_attachment.length > 0)
                changeAttachment(active_attachment, attachment);
            else
                openSubCategory(attachment);
        }
    }

    // function toggleAttachment(category) {
    //     $('.link_hover').filter(':not(.current_page_link)').hide();
    //     let option = $(category);
    //     let attachment = $(option.data('attachment'));
    //     option.find('.link_hover').css('display', 'flex');
    //     const active_attachment = $('.sub-nav-group.active')
    //     if (attachment == null) {
    //         if (active_attachment.length > 0) closeSubCategory(active_attachment);
    //     } else if (attachment.hasClass('active'))
    //         closeSubCategory(attachment);
    //     else if (active_attachment.length > 0)
    //         changeAttachment(active_attachment, attachment);
    //     else
    //         openSubCategory(attachment);
    // }

    function openSubCategory(attachment) {
        if (attachment == null) return
        if (attachment.is(":animated")) return
        // let animatedAttachment = $('.sub-nav-group:animated')
        // if (animatedAttachment) {
        //     animatedAttachment.stop(true, true);
        //     animatedAttachment.hide();
        // }
        $('.sub-nav-group:animated').stop(true, true).hide();
        navbar.css('backdrop-filter', 'blur(17px)')
        navbar.css('border-bottom', '1px solid rgb(255 255 255 / 20%)');
        attachment.slideDown(function () {
            attachment.addClass('active');
            if ($(window).width() < 992) navbar.addClass('opened-submenu');
        })
    }

    function closeSubCategory(attachment) {
        if (attachment[0] == null) return

        attachment.slideUp(function () {
            attachment.removeClass('active');
            if ($('.sub-nav-group:animated').length < 1) {
                navbar.css('backdrop-filter', 'blur(0)');
                navbar.css('border-bottom', 'none');
                navbar.removeClass('opened-submenu');
                $('.link_hover').filter(':not(.current_page_link)').hide();
            }
        })
    }

    function changeAttachment(previous, next) {
        if (previous.hasClass('closing')) return

        previous.addClass('closing')
        previous.animate({opacity: 0,},
            {
                duration: 150, easing: 'linear',
                complete: function () {
                    previous.hide().removeClass('active closing').css('opacity', 1)
                    next.css('display', 'flex').addClass('active').css('opacity', 0);
                    next.animate({
                        opacity: 1,
                    }, 150);
                }
            });
    }

    function offsetAnchor() {
        if (location.hash.length !== 0) {
            window.scrollTo(window.scrollX, window.scrollY - 400);
        }
    }

    global.toggleNavMap = function (button, map_page = false) {
        if ($('#menu-map').is(":animated")) return
        if (button.hasClass('nav-open')) {
            slideMapMenu(map_page);
            button.removeClass('nav-open');
            $('body').removeClass('stop-scrolling')
        } else {
            button.addClass('nav-open');
            slideMapMenu(map_page);
            if ($('.sub-nav-group.active').length > 0) closeSubCategory($('.sub-nav-group.active'));
            if (!$('#nav_main').hasClass('map-page')) {
                $('body').addClass('stop-scrolling')
            }
        }
    }

    global.slideMapMenu = function (map_page) {
        const menu = $('.map-additional-menu')
        if (map_page != 'map-page') {
            $('nav').toggleClass('map-open');
            $('#links-block').slideToggle();
            $('#menu-map').slideToggle(function () {
                menu.toggleClass('open');
                if (menu.hasClass('open')) { adjustMapMargins(); }
            });
        } else $('#menu-map').slideDown(function () {
            menu.toggleClass('open');
        })
    }

    $('.sub-nav-category').click(function () {
        closeSubCategory($('.sub-nav-group.active'))
    })

    navbar.mouseleave(function () {
        closeSubCategory($('.sub-nav-group.active'));
    })
    $('.single-link_hover').mouseleave(function () {
        $('.link_hover').filter(':not(.current_page_link)').hide();
    })

    $('.btn-nav-link').show();
    $('#btn-nav-map').detach().appendTo('.btn-nav-link');

    $(document).on('click', 'a[href^="#"]', function (event) {
        window.setTimeout(function () {
            offsetAnchor();
        }, 0);
    });

    $('.nav-category').mouseenter(function () {
        optionOpen(this)
    })

    if (window.location.pathname === "/") {
        var sectionIds = $('a.anchor');

        $(document).scroll(function () {
            let triggered = false;
            sectionIds.each(function () {
                var container = $(this).attr('href').slice();

                if ($(container).length < 1) return;
                var containerOffset = $(container).offset().top;
                var containerHeight = $(container).outerHeight();
                var containerBottom = containerOffset + containerHeight;
                var scrollPosition = $(document).scrollTop();
                let socialHover = $('#nav-social-links').find('.link_hover');
                if (scrollPosition + 250 < containerBottom && scrollPosition + 250 >= containerOffset) {
                    $(this).parent().addClass('current_page_link');
                    triggered = true;
                    socialHover.css('display', 'flex');
                    socialHover.addClass('current_page_link');
                } else {
                    $(this).parent().removeClass('current_page_link');
                    if (!triggered) {
                        socialHover.hide();
                        socialHover.removeClass('current_page_link');
                    }
                }
            });
        });

        var redirectAnchor = window.location.hash

        if (redirectAnchor.length > 0) {
            let target = $(redirectAnchor)
            let targetOffset = $(target).offset().top;
            $([document.documentElement, document.body]).animate({
                scrollTop: targetOffset - 60
            }, 200);
        }
    }

}
